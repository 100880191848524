.loginMain {
    min-height: 100vh;
    justify-content: center;
    align-items: center
}

.loginSubContainer {
    position: absolute;
    z-index: 11
}

.loginFormContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

.loginLogo {
    width: 200px;
    height: 60px;
    object-fit: contain
}

.loginForm {
    width: 100%;
    margin-top: 40px;
    padding: 30px;
    border-radius: 15px
}

.loginButton {
    text-transform: uppercase;
    width: 100%;
    height: 48px;
    margin-top: 20px
}