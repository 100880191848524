.ai-tools-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 0px 0px;
}
.ait-media-container {
  display: flex;
  margin-bottom: 16px;
}
.ait-media-box {
  flex: 1;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ait-media {
  flex: 1;
  aspect-ratio: 1;
  margin-top: 16px;
  background-color: rgb(13, 14, 19);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.ait-media p {
  color: #fff;
  text-align: center;
  position: absolute;
}
.ait-prompt {
  margin: 16px 24px;
  background-color: rgb(13, 14, 19) !important;
  border: none !important;
  border-radius: 8px;
  flex: 1;
  max-height: 150px;
  color: #fff;
  font-size: medium;
  padding: 8px;
}
.media-label {
  color: #fff;
  font-size: 1.2rem;
}
.ait-saved-prompts {
  flex: 1;
  display: flex;
  justify-content: center;
  margin: 0px 16px;
}
.ait-saved-prompts
  .css-19ff3lh-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: none;
}
.ait-saved-prompts .MuiSelect-iconOutlined {
  color: #fff;
}
.generate-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
#saved-prompts .deleteIconInMenuItem {
  display: none;
}
