.sendQuoteScreen {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}
.quoteInput {
  width: 100%;
  padding: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1.5rem;
  margin-bottom: 8px;
  border-radius: 6.27px 56.43px;
}
.quoteLength {
  width: 100%;
  color: rgba(255, 255, 255, 0.5);
  padding: 4px;
  text-align: center;
  font-size: 0.8rem;
}
.sendQuoteScreen .actionButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.sendQuoteScreen .actionButtons > * {
  flex: 1;
}
.quoteHeader {
  padding: 8px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
  font-size: 1.6rem;
  color: white;
}
.sendQuoteScreen .submitBtn:disabled {
  background-color: #ccc;
  color: #fff;
}
.loadingSpinnerContainer {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
