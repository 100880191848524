/* SocialSlideshow.css */
.slideshow-container {
  position: absolute;
  inset: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slideshow-controls {
  position: absolute;
  bottom: 10px; /* adjust this as needed */
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  display: flex;
  gap: 10px; /* space between buttons */
  opacity: 0;
  transition: opacity 0.3s;
}

.slideshow-container:hover .slideshow-controls {
  opacity: 1;
}

/* Fade transition */
.fade {
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Slide transition */
.slide {
  animation: slideIn 2s;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

/* Zoom transition */
.zoom {
  animation: zoomIn 2s;
}

@keyframes zoomIn {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

/* Rotate In transition */
.rotate {
  animation: rotateIn 2s;
}

@keyframes rotateIn {
  from {
    transform: rotate(-90deg);
    opacity: 0;
  }
  to {
    transform: rotate(0deg);
    opacity: 1;
  }
}

/* Flip In transition */
.flip {
  animation: flipIn 2s;
  backface-visibility: hidden;
}

@keyframes flipIn {
  from {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
  to {
    transform: perspective(400px) rotateY(0);
    opacity: 1;
  }
}

/* Slide In from Right transition */
.slideRight {
  animation: slideInRight 2s;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.card-slideshow {
  display: flex;
  justify-content: center;
  height: 100%;
  min-width: 400px;
  position: relative;
  overflow: hidden;
}

.img-slideshow,
.video-slideshow {
  width: 100%;
  height: auto;
}

.slideShowSocialMediaPortal {
  padding: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.slideShowSettingsIcon {
  position: fixed;
  top: 8px;
  right: 8px;
  color: white;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 100;
}

.slideShowSettingsIcon:hover {
  opacity: 1;
}

.slideShowDialogContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 200;
}
