.soRoot {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 8px;
  padding: 0px 16px;
}
.soContainer {
  margin: auto;
  justify-content: start;
  width: 100%;
  height: 100%;
  overflow: auto;
  max-height: calc(100vh - 100px);
  padding: 8px 8px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.soTabsBox {
  flex: 1;
  display: flex;
  justify-content: center;
}
.soTabButton {
  margin: 8px;
  border-width: 1px;
  border-style: solid;
}
.soCard {
  position: relative;
  background-color: transparent;
  height: calc(100% - 20px);
  border-radius: 14px;
  cursor: pointer;
}
.quoteOnlyCardOuter {
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.soEventInteractionCard {
  margin: 0px 10px;
}

.soImageMedia {
  width: 100%;
  height: 100%;
}

.quoteOnlyCard {
  color: rgba(255, 255, 255, 0.5);
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}

.soVideoMedia {
  position: absolute;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.soBottomButtons {
  width: calc(100% - 16px);
  padding: 0px 8px;
  background-color: var(--grey);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}

.soHeaderSocial {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 16px);
  padding: 0px 8px;
  background-color: var(--grey);
  border-radius: 8px;
  margin-bottom: 8px;
}
.soHeaderSocial > * {
  flex: 1;
}
.soOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1); /* semi-transparent background */
  z-index: 1000; /* this ensures the loader is on top */
}
.soCheckboxContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: 0px 8px;
}

.soSearchTextField {
  color: white;
  background-color: transparent;
  border-radius: 1px;
}
.soLoaderStyle {
  position: absolute;
  align-self: center;
  z-index: 11111;
  position: fixed;
  inset: 0px;
}

.soDownloadButton {
  position: absolute;
  bottom: 8px;
  right: 8px;
  z-index: 12;
  opacity: 0;
}
.soApproveButton {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 12;
  opacity: 0;
}
.soCard:hover .soApproveButton {
  background-color: rgba(255, 255, 255, 1);
  color: black;
  opacity: 0.6;
}

.soCard:hover .soDownloadButton {
  opacity: 0.6;
  background-color: rgba(255, 255, 255, 1);
  color: black;
}
.selectForBulkRemove {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 12;
  opacity: 0;
}
.rightTick {
  position: absolute;
  top: 8px;
  right: 56px;
  z-index: 12;
  opacity: 0;
}
.soCard:hover .rightTick,
.soCard:hover .selectForBulkRemove {
  opacity: 0.6;
  background-color: rgba(255, 255, 255, 1);
  color: black;
}
.star {
  position: absolute;
  bottom: 8px;
  left: 8px;
  z-index: 12;
  opacity: 0;
}
.soCard:hover .star {
  opacity: 0.6;
  background-color: rgba(255, 255, 255, 1);
  color: black;
}

.soDialogPaper {
  max-width: 1000px;
  height: 90vh;
  flex: 1;
  display: flex;
  background-color: #3e3e3e;
  border-radius: 14px;
}
.soDialogPaper .soDialogClose {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  cursor: pointer;
  z-index: 100;
}

.soDialogHeader {
  text-align: center;
  margin: 5px;
  color: white;
}

.soMediaPreview {
  display: flex;
  overflow: hidden;
  justify-content: center;
  flex: 3;
  position: relative;
}
.soMediaPreview .approvedImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.review-media-tab {
  color: rgba(255, 255, 255, 0.5);
}
.review-media-tab:hover {
  background: transparent;
}
.mediaPreviewInputFields .nameAndDescription {
  display: flex;
  margin-top: 16px;
}
.mediaPreviewInputFields .addressDiv {
  display: flex;
  margin-top: 8px;
}
.gpsAddress {
  display: flex;
  justify-content: center;
  align-items: center;
}
.soVideoPreviewContainer {
  width: 100%;
  height: 40vh;
}
.soDialogActions {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.soDialogActions > button,
.soDialogActions > div {
  flex: 1;
}

.soFirstrowdiv {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.soShowOnQuotesCheckbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: "0px 16px";
}
.soOverlayLoader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 22;
}
.starredCard {
  border: 1px solid #ffd700;
}
.eic-username {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.8);
  width: 100%;
}
.selected-approval {
  border: 2px solid #00ff00;
}
.selected-rejection {
  border: 2px solid #ff0000;
}
.selected-approval .thumb-up {
  opacity: 1;
  background-color: rgba(0, 255, 0, 0.4);
}
.selected-rejection .thumb-down {
  opacity: 1;
  background-color: rgba(255, 0, 0, 0.4);
}
