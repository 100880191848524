.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position:fixed;
  inset:0
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #0d1051;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
