.flip-card {
  background-color: transparent;
  perspective: 1000px;
}
.flip-card-inner {
  position: relative;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  inset: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.flip-card-front {
  transform: perspective(2000px) rotateY(0deg);
}

.flip-card-back {
  transform: perspective(1000px) rotateY(-180deg);
}
.flip-card.flipToggle .front {
  transform: perspective(1000px) rotateY(180deg);
}

.flip-card.flipToggle .back {
  transform: perspective(1000px) rotateY(0deg);
}
.flip-card-inner img,
.flip-card-inner video {
  overflow: hidden;
}
.flip-card-inner img.uv-main-media-tag,
.flip-card-inner video.uv-main-media-tag {
  scale: 1.05;
}
