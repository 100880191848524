.flashCover {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
}

.white-div {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hole {
  position: absolute;
  width: 100vw; /* Diameter of the hole */
  aspect-ratio: 1;
  background-color: transparent;
  border-radius: 50%; /* Makes the hole circular */
  box-shadow: 0 0 0 9999px rgba(255, 255, 255, 0.8);
}
