:root {
  --grey: #222934;
  --grey-1: #323a4a;
  --btn-blue: #24b8e7;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.halfWidth {
  width: 50%;
}

.fullWidth {
  width: 100%;
}
.leftAlign {
  text-align: left;
}
.lightFont {
  color: darkgray;
}

.react-swipeable-view-container {
  transform: translate(0px, 0px) !important;
}
.web-app-game-option {
  position: relative;
  overflow: hidden;
  margin-bottom: 16px !important;
  margin-top: 0px !important;
  color: white !important;
  border: none;
  border-radius: 200px;
  background-color: rgba(36, 184, 231, 0.2) !important;
}
.web-app-game-option:focus,
.web-app-game-option:active,
.web-app-game-option:hover {
  color: white !important;
  background-color: #24b8e7 !important;
  border: none;
}
.web-app-game-option.result-variant {
  pointer-events: none;
}
.web-app-game-option .percentSpan {
  position: absolute;
  inset: 0;
  background-color: #24b8e7 !important;
  transition: width 0.8s ease;
  width: 0px;
}
.correct-answer-btn {
  background-color: green;
}
.wrong-answer-btn {
  background-color: "red";
}
.jelgPosterContainer {
  width: 100%;
  aspect-ratio: 16 / 9;
}
.grey-button {
  color: white;
  min-width: 280px !important;
  margin: 16px auto !important;
  padding: 8px !important;
}
.uv-datepicker * {
  color: #fff;
  border: 0px;
}
.UvMuiColorInputSmall .MuiInputBase-input,
.UvMuiColorInputSmall fieldset {
  display: none !important;
}
.UvcardContent {
  padding-top: 0px;
}
.MuiDialogContent-root {
  padding-top: 2px !important;
}
video::-internal-media-controls-overlay-cast-button {
  display: none;
}
.media-card-name-likes {
  position: absolute;
  color: rgba(255, 255, 255, 0.75);
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.35);
  padding: 4px 6px;
  font-family: Montserrat;
  font-weight: 700;
  text-align: start;
  border-radius: 0 0 0.35rem;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-image-editor {
  margin: 0 auto;
}

.e-img-editor-sample {
  width: 100%;
  object-fit: contain;
  height: 100%;
}

/* @media only screen and (max-width: 700px) {
  .e-img-editor-sample {
    height: 70vh;
    width: 100%;
  }
} */

.control-wrapper {
  height: 100%;
}

.led-box {
  width: 18px;
  height: 18px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}
.led-red {
  background-color: red;
  position: absolute;
  inset: 0;
}
.led-green {
  background-color: green;
  position: absolute;
  inset: 0;
}
.pointerEventsNone {
  pointer-events: none;
}

.skeleton {
  background-color: hsla(218, 20%, 17%, 1);
  background-image: linear-gradient(
    90deg,
    hsla(218, 20%, 17%, 0.5),
    hsla(218, 20%, 22%, 0.5),
    hsla(218, 20%, 17%, 0.5)
  );
  background-size: 40% 100%;
  background-repeat: no-repeat;
  background-position: left -40% top 0;
  animation: shine 2s ease infinite;
}

@keyframes shine {
  to {
    background-position: right -40% top 0;
  }
}

@font-face {
  font-family: "DinMedium";
  src: url("https://db.onlinewebfonts.com/t/39d8205ea5850c9f82199ce84cb05aca.eot");
  src: url("https://db.onlinewebfonts.com/t/39d8205ea5850c9f82199ce84cb05aca.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/39d8205ea5850c9f82199ce84cb05aca.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/39d8205ea5850c9f82199ce84cb05aca.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/39d8205ea5850c9f82199ce84cb05aca.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/39d8205ea5850c9f82199ce84cb05aca.svg#DIN Medium")
      format("svg");
}

@font-face {
  font-family: "DINNextLTPro-Heavy";
  src: url("https://db.onlinewebfonts.com/t/1f83f5c8497875fdc52c54ee78331a55.eot");
  src: url("https://db.onlinewebfonts.com/t/1f83f5c8497875fdc52c54ee78331a55.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/1f83f5c8497875fdc52c54ee78331a55.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/1f83f5c8497875fdc52c54ee78331a55.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/1f83f5c8497875fdc52c54ee78331a55.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/1f83f5c8497875fdc52c54ee78331a55.svg#DINNextLTPro-Heavy")
      format("svg");
}

@font-face {
  font-family: "DINNextLTPro-Regular";
  src: url("https://db.onlinewebfonts.com/t/5b0be84ee7019608e3e098c541290494.eot");
  src: url("https://db.onlinewebfonts.com/t/5b0be84ee7019608e3e098c541290494.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/5b0be84ee7019608e3e098c541290494.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/5b0be84ee7019608e3e098c541290494.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/5b0be84ee7019608e3e098c541290494.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/5b0be84ee7019608e3e098c541290494.svg#DINNextLTPro-Regular")
      format("svg");
}

@font-face {
  font-family: "EndzoneSansCond-Bold";
  src: url("https://db.onlinewebfonts.com/t/a3e50321336d6ba9d891aa235041dad8.eot");
  src: url("https://db.onlinewebfonts.com/t/a3e50321336d6ba9d891aa235041dad8.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/a3e50321336d6ba9d891aa235041dad8.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/a3e50321336d6ba9d891aa235041dad8.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/a3e50321336d6ba9d891aa235041dad8.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/a3e50321336d6ba9d891aa235041dad8.svg#Endzone Sans Cond Bold")
      format("svg");
}
.switchStack {
  align-items: center;
}

/* Chrome, Safari, Edge, Opera */
input.no-spinner::-webkit-outer-spin-button,
input.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.no-spinner[type="number"] {
  -moz-appearance: textfield;
}
