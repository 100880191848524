/* live output preview view styles */
.mainImgDiv {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outputPreviewHolderDiv {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 10px;
}

.infoActionsView {
  display: flex;
  z-index: 30;
  position: absolute;
  width: 100%;
  height: 35px;
  justify-content: center;
  align-items: center;
}

.liveOutputText {
  font-weight: 800;
  padding: 5px;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 5px;
}

.stopIconHolder {
  top: 1px;
  right: 1px;
  position: absolute;
  display: flex;
  justify-content: "center";
  align-items: "center";
  border-top-right-radius: 5px;
}

.stopIconStyle {
  width: 25px;
  margin: 3px;
}

.outputPreviewVideo {
  z-index: 20;
  box-shadow: 0 0 1000px rgba(#000, 1);
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

/* user preview view styles */
.userPreviewHolderDiv {
  display: flex;
  aspect-ratio: 0.75;
  position: relative;
  border-radius: 10px;
  background-color: rgb(13, 14, 19);
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.liveUserText {
  font-weight: 800;
  padding: 3px;
  border-radius: 2px;
  position: absolute;
  top: 2%;
  left: 2%;
}

.nameHolder {
  bottom: 0px;
  position: absolute;
  opacity: 0.8;
  display: flex;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  justify-content: center;
}

.nameStyle {
  font-weight: 600;
  width: 100%;
  word-wrap: break-word;
  text-align: center;
}

.userPreviewVideo {
  z-index: 20;
  box-shadow: 0 0 1000px rgba(#000, 1);
  width: 100%;
  object-fit: contain;
}

.loaderStyle {
  position: absolute;
  align-self: center;
  z-index: 110;
}

.speedStatus {
  position: absolute;
  right: 5px;
  width: 10px;
  height: 10px;
  align-self: center;
  border-radius: 5px;
}

.disconnectionHolder {
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  z-index: 111;
}

.disconnectionStyle {
  font-weight: 600;
  width: 100%;
  word-wrap: break-word;
  text-align: center;
}
/* Live users index styles */
.mainCard {
  display: flex;
  flex: 1;
  background-color: transparent;
}

/* LoadPreview.js styles */
.videoSection {
  position: relative;
  flex: 4;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow-y: auto;
  border-radius: 8px;
  
}

.liveOutputView {
  width: 100%;
  object-fit: contain;
  aspect-ratio: 1.77;
  height: 30%;
  border-radius: 8px;
}

.liveStreamingButton {
  z-index: 100;
  align-self: center;
  position: absolute;
  bottom: 10px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 5px;
  word-spacing: 5px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  transition: 0.3s, color 0.3s;
  text-transform: uppercase;
}

.overlayLoader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noUserSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(70% - 8px);
  margin-top: 8px;
  border-radius: 8px;
  background-color: #222934;
}

.infoText {
  margin-top: 10px;
  font-weight: 700;
  letter-spacing: 2px;
  z-index: 10;
}

/* VideoElement.js styles */
.backgroundImage {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 70%;
  bottom: 0;
}

.veCanvasStyle {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70%;
  filter: blur(15px);
  z-index: 10;
}

.videoContainer {
  width: 100%;
  height: calc(70% - 24px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border: 2px solid white;
  border-radius: 8px;
}

.veVideoElementContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(70% - 8px);
  margin-top: 8px;
  border-radius: 8px;
  background-color: #222934;
}

.veVideoElement {
  position: absolute;
  z-index: 20;
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* RenderOnlineUsers styles */
.rouDrawerOuterDiv {
  position: relative;
  flex: 6;
  background-color: #222934;
  border-radius: 8px;
  margin-right: 8px;
}

.rouDrawerOuterDiv .MuiModal-backdrop {
  opacity: 0 !important;
}

.tabsBox {
  flex: 1;
  display: flex;
  justify-content: center;
}

.rouDrawer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.usersText {
  margin: 10px;
  padding: 5px;
  flex: 1;
  border-bottom: 1px solid grey;
  align-self: center;
  text-align: center;
}

.bottomPaginationStrip {
  display: flex;
  padding: 0px 8px;
  position: absolute;
  border-radius: 60px;
  top: 10px;
  right: 10px;
  box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.4);
  z-index: 1222;
  justify-content: center;
  opacity: 0.4;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.liveUserVolumeToggle {
  position: absolute;
  opacity: 0.6;
  top: 4px;
  right: 4px;
  z-index: 100;
  background-color: #222934;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: white;
}
