/* movable library supporting css */
.moveable-line {
  background: green !important;
}

.moveable-control {
  border: none !important;
  border-radius: 0px !important;
  background: green !important;
}

/* output screen styles */
.userOutputCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(15px);
  z-index: 0;
}

.userOutputVideoView {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userOutputVideoElement {
  height: 100%;
  max-width: 100%;
  z-index: 1;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.3s linear;
}

/* .opacityOne {
  opacity: 1;
} */

.outputBrandOverlay {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 999;
  object-fit: contain;
}

.outputBackgroundImage {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
}