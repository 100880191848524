.light-show-console {
  display: flex;
  align-items: center;
}
.light-show-console > div {
  flex: 1;
  padding: 20px;
}

.light-show-preview {
  height: 100%;
  width: 100%;
  position: relative;
}
.phone-frame {
  width: auto;
  max-height: calc(100% + 103px);
}
/* .light-show-preview > img {
  position: absolute;
  inset: -35.5px;
} */

.set-light-show {
  display: flex;
  flex-direction: column;
  border-right: 1px solid grey;
  align-items: flex-start;
}
.set-light-show > div {
  padding: 10px;
}
.set-light-show label {
  margin-right: 24px;
}
.MuiColorInput-TextField {
  color: white;
}
.play-stop-button {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: transparent;
  color: rgba(0, 128, 0, 0.7);
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  width: 100px;
}
.play-stop-button:not(.active):hover {
  background-color: rgba(0, 0, 0, 0.54);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  transition: box-shadow color 300ms cubic-bezier(0.4, 0, 0.2, 1);
  color: rgba(0, 128, 0, 1);
}
