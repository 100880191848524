.root-container-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.uv-social-grid {
  display: grid;
  grid-auto-flow: dense;
}
.social-grid-vertical-card {
  height: 100%;
  aspect-ratio: 9 / 16;
}
