/* social feed styles */
.feedView {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  overflow: auto;
}

.jelsfLoaderContainer {
  position: absolute;
  bottom: 47px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.jelsfFeed {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  overflow: auto;
}

.mswtMain * {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.mswtMain *::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.jelsfFeedItem {
  width: 100%;
}

.mediaItemContainer {
  margin: 0px 5px;
  border-radius: 14px;
}
.mediaItemContainer .textContent {
  padding: 8px 10px;
}
.mediaItemContainer .textContent > * {
  margin: 2px 0px;
  font-size: smaller;
}

.jelsfMediaItem {
  width: 100%;
  display: block;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

.cardUsername {
  color: white;
  font-weight: bold;
}

.cardDescription {
  color: white;
}

.adItem {
  width: 100%;
  display: block;
  margin-top: 10px;
  border: none;
  text-decoration: none;
}

.jelsfActionBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: #279fcc;
  text-transform: uppercase;
}

.adTitle {
  margin-left: 10px;
  color: white;
}

.noFeedItems {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.noFeedInfo {
  margin-top: 10px;
  color: white;
  font-size: 24px;
  text-align: center;
}

/* video player styles */
.jelsfmediaItemVideo {
  display: block;
  z-index: 998;
  width: 100%;
  height: auto;
}

.jelsfMuteIcon {
  position: absolute;
  right: 4px;
  bottom: -8px;
  transform: translateY(100%);
  width: 28px;
  height: 28px;
  z-index: 999;
}
.skeletonCard {
  width: calc(100vw - 10px);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.skeletonCard .mediaItemContainer {
  border-radius: 14px;
  height: 100%;
  background-color: rgb(25, 25, 25);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 10px;
}
.skeletonCard .mediaItem {
  flex: 1;
  width: 100%;
}
.skeletonCard .textContent {
  width: 100%;
  box-sizing: border-box;
  padding: 8px 10px;
}
.skeletonCard .textContent .skeleton {
  width: 30%;
  height: 20px;
  border-radius: 4px;
  background-size: 5% 100% !important;
}

.invisibleItem{
  width: 100%;
  height: 0px !important;
  overflow: hidden !important;
  visibility: hidden !important;
}
