.light-show-preview-web-app {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.lsAdContainer {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lsThank {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-size: large;
}
.lsAdMedia {
  height: 100%;
  width: auto;
}
.brightnessMessageContainer {
  position: fixed;
  top: calc(100% - 100px);
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 10;
  max-height: 30vh;
}
.lsMediaContainer {
  position: absolute;
  max-width: 80%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.logoContainerInPreview {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoContainerInPreview .lsMediaContainer {
  margin-left: 0;
}
.phoneDiv {
  aspect-ratio: 0.51;
}
.light-show-web-app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
