.advertParentDiv {
  display: flex
}

.bgMedia {
  width: 300px;
  height: 200px;
  margin: 5px;
  padding: 5px;
  border-style: solid;
  border-width: 2px;
  border-radius: 10px;
}

.backgroundRoot {
  max-width: 600;
  width: 100%;
}

.adsCardContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column
}

.badgeDiv {
  cursor: pointer
}

.selectAvatar {
  border: 1px solid white;
}

.closeAvatar {
  border: 1px solid white;
  width: 25px;
  height: 25px;
}

.doneButton {
  width: 20%;
  font-weight: 600;
  border: 1px solid;
}

.cancelButton {
  width: 20%;
  border: 1px solid;
}