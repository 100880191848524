/* guest usernamepopup - index styles */
.gupParentDiv {
  display: flex;
  justify-content: center;
}

.gupRoot {
  max-width: 500px;
  width: 100%;
  margin: 10px;
}

.guestInfo {
  margin-bottom: 10px;
  text-align: center;
}

.privacyInfo {
  margin-top: 10px;
  text-align: center;
  white-space: pre-line;
}

/* guest usernamepopup - modal styles */
.gupDialogTitle {
  text-align: center;
}

.gupEventInfo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gupThumbnailStyle {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  object-fit: cover;
}

.gupEventName {
  font-weight: bold;
  margin-left: 10px;
  text-transform: uppercase;
}

.gupContinueButton {
  padding: 10px;
  width: 70%;
  margin-bottom: 10px;
}