:root {
  --color-primary: #24b8e7;
  --color-primary-hover: #24b8ff;
  --color-dark-100: #222934;
  --color-dark-300: #2a2d33;
  --color-dark-400: #383b44;
  --color-dark-500: #4a4e5a;
  --color-dark-600: #6b7280;
  --color-dark-800: #d1d5db;
}

/* Toolbar */
.toolbar {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* Search */
.search-container {
  position: relative;
  display: flex;
}

.search-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.25rem;
  height: 1.25rem;
  color: var(--color-dark-600);
  pointer-events: none;
}

.search-input {
  width: 100%;
  padding: 0.625rem 0.75rem 0.625rem 2.5rem;
  font-size: 0.875rem;
  color: var(--color-dark-800);
  background-color: var(--color-dark-100);
  border: 1px solid var(--color-dark-400);
  border-radius: 0.375rem;
  transition: all 0.2s;
  min-width: 200px;
}

.search-input:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 1px var(--color-primary);
}

.search-input::placeholder {
  color: var(--color-dark-600);
}

/* Toolbar Actions */
.toolbar-actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.toolbar-button {
  display: inline-flex;
  align-items: center;
  gap: 0.1rem;
  padding: 0.25rem 0.75rem;
  color: var(--color-dark-800);
  background-color: var(--color-dark-100);
  border: 1px solid var(--color-dark-100);
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s;
}

.toolbar-button:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.toolbar-button:hover .button-icon {
  color: var(--color-white);
}

.toolbar-button.primary {
  color: white;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.toolbar-button.primary:hover {
  background-color: var(--color-primary-hover);
  border-color: var(--color-primary-hover);
}

.toolbar-button .button-icon {
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.25rem;
}

.chevron-icon {
  width: 1rem;
  height: 1rem;
  transition: transform 0.2s;
}

.chevron-icon.rotate {
  transform: rotate(180deg);
}

/* Dropdown */
.dropdown {
  position: relative;
}

.dropdown-content {
  position: absolute;
  right: 0;
  top: calc(100% + 0.5rem);
  width: 14rem;
  padding: 0.5rem;
  background-color: var(--color-dark-100);
  border: 1px solid var(--color-dark-400);
  border-radius: 0.375rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.3),
    0 4px 6px -4px rgba(0, 0, 0, 0.2);
  z-index: 50;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  font-size: 0.875rem;
  color: var(--color-dark-800);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-item:hover {
  background-color: var(--color-dark-300);
}

.checkbox {
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--color-dark-500);
  background-color: var(--color-dark-100);
  cursor: pointer;
  accent-color: var(--color-primary);
}
.toolbar-actions .total {
  padding: 0.5rem 0.75rem;
  color: var(--color-primary);
}

/* Responsive */
@media (max-width: 640px) {
  .toolbar {
    flex-direction: column;
    align-items: stretch;
  }

  .toolbar-actions {
    flex-wrap: wrap;
  }

  .toolbar-button {
    flex: 1;
    justify-content: center;
  }
}
