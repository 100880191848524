/* games */
.jelgRoot {
  width: 100%;
  display: flex;
  overflow: auto;
  position: relative;
  justify-content: center;
}

.jelgMainContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.jelgImage {
  width: 100%;
  height: auto;
  margin-bottom: -4px;
}

.questionContainer {
  width: 100%;
  margin-top: 0px;
  padding: 2px;
  text-align: center;
}

.questionText {
  color: white;
  text-wrap: wrap;
  text-align: center;
  font-size: 1.5rem;
  padding: 8px;
  margin: 0px;
  margin-bottom: 8px;
}

.timerContainer {
  width: 100%;
  height: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.timerOverlay {
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100px;
  height: 100px;
  border-radius: 50px;
  z-index: 2;
}

.timerText {
  color: white;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 14px;
}

.timerSubtitle {
  color: white;
  font-size: 14px;
}

.optionsContainer {
  width: calc(100% - 40px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
}

.gameContainer {
  text-align: center;
}

.textBelowImage {
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 0px;
  padding: 8px;
  text-align: center;
}
.textBelowImage.gameTitle {
  color: white;
  padding: 12px;
  font-size: 1.5rem;
}

.jelgButtonContainer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.postGameAd {
  display: flex;
  width: calc(100% + 2px);
  flex-direction: column;
  align-items: center;
  position: relative;
}

.videoAd {
  width: 100%;
  max-height: calc(100vh - 320px);
  object-fit: contain;
}

.imageAd {
  width: calc(100% - 4px);
  object-fit: contain;
  max-height: calc(100vh - 320px);
}

.jelgMuteIcon {
  position: absolute;
  padding: 0;
  margin: 0;
  top: 4px;
  right: 4px;
}

.jelgActionBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -5px;
  width: 100%;
  height: 40px;
  background-color: #24b8e7;
}

.adTitle {
  margin-left: 10px;
  color: white;
}

.adAction {
  margin-right: 10px;
  color: white;
}

.jelgFullscreenEmailText {
  margin-bottom: 10px;
  color: white;
  text-align: center;
}
.game-cards-container {
  display: flex;
  overflow-x: auto;
  gap: 1rem;
  padding: 1rem 1rem 0 1rem;
  scroll-snap-type: x mandatory;
  scroll-padding: 1rem;
  flex: 1;
}
.game-cards-container .game-card {
  position: relative;
  min-width: 200px;
  flex: 0 0 auto;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  scroll-snap-align: start;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

circle {
  stroke-dashoffset: 0;
  stroke-width: 7%;
  fill: none;
}

.bg {
  stroke: rgba(36, 184, 231, 0.2);
}

#progress .indicator {
  stroke: #24b8e7;
}
#progress {
  align-self: center;
  z-index: 1002;
}

.multiple-games-root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.cardNavigationContainer {
  padding: 0 1rem 1.5rem 1rem;
  position: relative;
  display: flex;
  align-items: center;
}
.navigation-button {
  flex: 1;
  color: #24b8e7;
}
.navigation-button:disabled {
  color: rgba(255, 255, 255, 0.26);
  border-color: rgba(255, 255, 255, 0.26);
}
