.flip-card-quote {
  background-color: transparent;
  perspective: 1000px;
  aspect-ratio: 16/9;
}
.flip-quote-card-inner {
  position: relative;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}
.flip-quote-card-inner h2 {
  text-align: center;
  margin: 0px;
  text-transform: capitalize;
}
.flip-quote-card-inner h2::first-letter {
  text-transform: uppercase;
}
.flip-quote-card-inner .flip-card-front,
.flip-quote-card-inner .flip-card-back {
  position: absolute;
  inset: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: visible;
  aspect-ratio: 16/9;
  transform-origin: top;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-blend-mode: soft-light;
}

.flip-card-front {
  transform: perspective(2000px) rotateY(0deg);
}

.flip-card-back {
  transform: perspective(1000px) rotateY(-180deg);
}
.flip-card.flipToggle .front {
  transform: perspective(1000px) rotateY(180deg);
}

.flip-card.flipToggle .back {
  transform: perspective(1000px) rotateY(0deg);
}
.flip-quote-card-inner img,
.flip-quote-card-inner video {
  overflow: hidden;
}

.flip-quote-card-inner .cardBody {
  display: flex !important;
  height: 100%;
  width: 100%;
}
.flip-quote-card-inner .description {
  position: relative;
  font-family: Montserrat;
  font-style: oblique;
  display: flex;
  justify-content: center;
  max-height: 87%;
  max-width: 98%;
}
.flip-quote-card-inner p {
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: lowercase;
}
.flip-quote-card-inner p::first-letter {
  text-transform: uppercase;
}
.flip-quote-card-inner .imageContainer-flip {
  position: relative;
  width: auto;
  height: 100%;
  top: 0;
  aspect-ratio: 9 / 16;
  overflow: hidden;
  background-color: black;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  z-index: 1;
}
.flip-quote-card-inner .inner {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}
.flip-card-quote .imageContainer-flip img:not(.blur-bg-media),
.flip-card-quote .imageContainer-flip video:not(.blur-bg-media) {
  object-fit: contain;
}
.flip-card-quote .textContainer {
  flex: 1;
}
.noMediaQuote {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.uv-main-media-tag {
  width: 100%;
  height: auto;
}
.flip-card-quote .quote-icon-container-2 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  border-color: white;
  z-index: -1;
}
.flip-card-quote .top-side {
  top: -1px;
  left: 0px;
  transform: rotate(180deg);
}
.flip-card-quote .bottom-side {
  bottom: -1px;
  right: 0px;
}
.flip-card-quote .quote-icon-container-2:after {
  content: " ";
  position: absolute;
  left: 0;
  top: -1px;
  width: 0;
  height: 0;
  border-top: 13px solid currentColor;
  border-left: 100% solid transparent;
  border-right: 100% solid transparent;
}
.flip-card-quote .blur-bg-media {
  object-fit: fill;
  filter: blur(10px);
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  z-index: -1;
  overflow: hidden;
}
.flippingCardContainer {
  position: relative;
  top: -15%;
  background: transparent;
  z-index: 1;
  width: auto;
  height: 120%;
  aspect-ratio: 9 / 16;
}
