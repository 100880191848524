.bottomBorder
{
  width: 100vw;
  background-color: #1f7a78;
  height: 50px;
  position: fixed;
  bottom: 0px;
  left: 0px;
}

.navigationPanel
{
    width: 100%;
    height: 70px;
    border-bottom-style: solid;
    border-radius: 3px;
    border-bottom-color: darkgray;
}

@media (max-width:600px)
{
    .navigationPanel
    {
        height: 50px
    }
}

.darkGrey
{
    color: #424242;
}