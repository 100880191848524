.ssSuperScreenPortal {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  max-height: 100vh;
}
.ssControlIconsContainer {
  top: 8px;
  left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  z-index: 100;
}

.ssDialogContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 200;
}

.ssDragText {
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
  z-index: 100;
  transform: translateY(-100%);
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0px 4px;
  left: -2px;
  right: -2px;
}

.ssUrlTextContainer {
  display: flex;
  align-items: center;
}

.ssEditScreensContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.ssOverlayImageSetting {
  width: 100%;
  max-height: 150px;
  margin: 10px 0;
  object-fit: cover;
}

.ssBackgroundImageSetting {
  width: 100%;
  max-height: 150px;
  margin: 10px 0;
  object-fit: cover;
}

.ssOverlayImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  z-index: 4;
}

.ssRemoveIcon {
  position: absolute;
  top: 0;
  right: 0;
}
.ssDragTextInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: grab;
  width: 100%;
}

.ssDraggingStyle {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1200;
  cursor: grab;
}

.ssScreenIdText {
  text-align: left;
  color: white;
}

.ssIframeStyle {
  width: 100%;
  height: calc(100% - 40px);
  border: none;
}

/* movable library css */
.superscreeninstance + .moveable-control-box .moveable-line {
  background: red !important;
}

.superscreeninstance + .moveable-control-box .moveable-control {
  border: none !important;
  border-radius: 0px !important;
  background: red !important;
}
.moveable-control-box {
  z-index: 1200 !important;
}
