.st-container {
  display: flex;
  flex: 1 1;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}
.st-container > .st-tab {
  position: relative;
  width: 100%;
  flex: 0 0 auto;
  scroll-snap-align: start;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
