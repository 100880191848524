.variants-select-container {
  position: fixed;
  top: 10px;
  left: 20px;
  z-index: 1;
  background: #fff;
}
.variant-icon {
    position: fixed;
    top: 8px;
    right: 48px;
    color: white;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 100;
  }
  .variant-icon:hover {
    opacity: 1;
  }