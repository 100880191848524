.layoutContainer {
  display: flex;
  flex-direction: column;
  height: 100vh !important;
  position: fixed;
  left: 250px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
}

.pageContent {
  display: flex;
  flex-grow: 1;
  margin: 20px;
  z-index: 11;
  max-height: calc(100% - 102px);
  overflow: auto;
}

.MuiFilledInput-underline:before,
.MuiFilledInput-underline:after {
  border: none !important;
}

.MuiInput-underline:after {
  border-bottom: #24b8e7 !important;
}

.textLabel {
  width: 100%;
  font-size: 14px;
  margin-top: 20px;
}

/* styles - layout.js */
.backgroundShadowImage {
  position: fixed;
  width: calc(100% - 250px);
  height: 50%;
  bottom: 0px;
  z-index: 0px;
}

.drawer {
  width: 250px;
  flex-shrink: 0px;
}

.drawerList {
  margin-top: 20px;
}

.topBar {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 60px;
  padding: 0px 12px;
  position: sticky;
  top: 0px;
  z-index: 1111;
  transition: top 1s ease-in-out;
}
.topBar.mobile-web-app-header{
  justify-content: space-between;
  padding: 0px;
}
.topBar.hideHeader {
  position: absolute;
  top: -60px;
  transition: top 1s ease-in-out;
  width: 100%;
}

.nameContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50%;
}

.iconButton {
  width: 1.5em;
  height: 1.5em;
  margin: 4px;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  align-items: center;
}

.logoContainer {
  display: flex;
}

.logo {
  margin: 30px;
  width: calc(100% - 60px);
  object-fit: contain;
}

.topBar2 {
  display: flex;
  align-items: center;
}

/* styles - layout.js */
