/* Event list styles */
::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
}

::-webkit-scrollbar-thumb:horizontal {
  height: 0px;
  display: none;
}

::-webkit-scrollbar-track:horizontal {
  height: 0;
  display: none;
}

.elCardStyle {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 24px;
  border: 1px solid;
  justify-content: space-between;
}

.eventImage {
  width: 50%;
  height: 50%;
  border-radius: 10px;
}

.eventName {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 700;
}

.eventInfo {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.eventCardText {
  font-size: 12px;
}

.elActions {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 1;
}

.actionIconStyle {
  justify-content: flex-end;
}

.cancelButton {
  width: 20%;
  border: 1px solid;
}

.deleteButton {
  width: 20%;
  border: 1px solid;
}

/* create/edit event styles */
.ceeRoot {
  display: flex;
  justify-content: center;
}

.large {
  width: 200px;
  height: 200px;
}

.ceeTextLabel {
  font-size: 14px;
  text-align: left;
  margin-bottom: 10px;
}

.ceeButton {
  font-weight: 600;
}

.createButton {
  width: 40%;
  font-weight: 600;
}

.ceeCancelButton {
  width: 40%;
  background-color: transparent;
  border: 1px solid;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  height: calc(100vh - 120px);
}

.gridItem {
  max-width: 100%;
  padding: 10px;
  text-align: center;
  overflow: auto;
}
.ceGridItem {
  max-width: 100%;
  text-align: center;
  overflow: auto;
}

.googleMapContainer {
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
}

.ceeActionsDiv {
  display: flex;
  justify-content: center;
  margin: 10px;
  height: 40px;
}
.eventCardsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-template-rows: auto;
  justify-content: center; /* Center the grid items */
  width: 100%;
  gap: 24px;
}
.eventCardGridItem {
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
@media  screen and (max-width: 768px) {
  .eventCardsGrid {
    grid-template-columns: repeat(auto-fill, 250px);
    gap: 20px;
  }
  .eventCardGridItem {
    width: 250px;
    height: 250px;
  }
  
}
