.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
  animation: fadeInOut 5s forwards;
  opacity: 0;

  width: 20px;
  height: 20px;
  background-color: #000;
  border-radius: 50%;
}

.react-resizable-handle-se:hover {
  animation: fadeIn 0.2s forwards;
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* parent resizable container */
.resizable-box {
  position: relative;
}

/* handle becomes visible when hovering over the parent resizable container */
.resizable-box:hover .react-resizable-handle-se {
  animation: fadeIn 15s forwards;
}

/* handle fades out when not hovering over the parent resizable container */
.resizable-box:not(:hover) .react-resizable-handle-se {
  animation: fadeInOut 15s forwards;
}
/* Additional CSS code */
.gameScreenVideoViewStyle {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  overflow: hidden;
}
.gameScreenBackgroundImage {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
}
.gameScreenQuestionText {
  text-align: center;
  color: white;
}

.gameScreenOptionContainer {
  display: flex;
  align-items: center;
  height: 100%;
}
.lock-icon {
  position: fixed;
  top: 8px;
  right: 56px;
  color: white;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 100;
}
.lock-icon:hover {
  opacity: 1;
}
.ruler {
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.6) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.6) 1px, transparent 1px);
}
