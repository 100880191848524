/* bottom tabs styles */
.bottomNavigation {
  width: 100%;
  justify-content: space-around;
  border-top: 1px solid white;
  position: relative;
  z-index: 0;
  padding: 2px 0px;
  transition: height 1s ease-in-out;
}
.bottomNavigation.hideFooter {
  padding: 0;
  height: 0;
  border: none;
  transition: height 1s ease-in-out;
}
/* join event live - live tab styles */
.jelMainContainer {
  flex: 1;
  display: flex;
}

.jelStreamContainer {
  padding: 0px !important;
  position: absolute;
  top: 0px !important;
  left: 0px !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.cameraButton {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 999;
}

.signallingView {
  display: flex;
  height: 100px;
  width: 250px;
  border-radius: 10px;
  justify-content: center;
  position: absolute;
  z-index: 100;
}

.signallingViewPortrait {
  left: 50%;
  transform: translate(-50%);
  bottom: 35px;
}

.signallingViewLandScape {
  right: 10px;
  bottom: 8px;
}

.statusTextStyle {
  font-size: 20px;
  text-transform: uppercase;
  align-self: center;
  padding: 8px;
}

.adsContainer {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

/* mainScreenWithTabs styles */
.mswtRoot {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  inset: 0;
}

.mswtMain {
  flex: 1;
  display: flex;
  overflow: auto;
  position: relative;
}

.mswtLoaderContainer {
  position: absolute;
  align-self: center;
}

.mswtPoweredByContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  width: 100%;
  position: absolute;
  z-index: 1000;
}

.uvenuLogo {
  height: 30px;
  object-fit: contain;
}

.fullscreenAdContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1110;
}

.fullscreenVideoAd {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
}

.fullscreenAdMuteIcon {
  position: absolute;
  top: 70px;
  right: 20px;
  z-index: 2;
  width: 30px;
  height: 30px;
  opacity: 0.7;
}

.fullscreenImageAd {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.fullscreenEmailContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 80%;
  background-color: rgba(30, 30, 30, 1);
  justify-content: center;
  align-items: center;
  z-index: 1111;
  border-radius: 10px;
  padding: 10px;
}

.fullscreenEmailText {
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
}

.fullscreenEmailTextField {
  color: white;
  background-color: grey !important;
  border-radius: 15px;
  width: 95%;
}

.fullscreenAdinfoText {
  margin-bottom: 10px;
  color: white;
  text-align: center;
  font-size: 12px;
}

.fullscreenAdButton {
  background-color: rgb(45, 96, 246);
}

.closeProgress {
  position: absolute;
  top: 30px;
  right: 20px;
  z-index: 9993;
  color: cyan;
  border-radius: 20px;
  cursor: pointer;
}

.closeButton {
  display: flex;
  position: absolute;
  top: 30px;
  right: 20px;
  width: 30px;
  height: 30px;
  z-index: 9992;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 20px;
  background-color: rgba(51, 51, 51, 1);
  opacity: 0.7;
}

.webAppTabsContainer {
  display: flex;
  flex: 1 1;
  gap: 1px;
  overflow-x: auto;
  padding: 8px 8px 0;
  scroll-padding: 8px;
  scroll-snap-type: x mandatory;
}
.webAppTabsContainer > div {
  position: relative;
  width: 100%;
  flex: 0 0 auto;

  scroll-snap-align: start;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
}
.social-bottomTab {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.6);
}
.social-bottomTab:hover {
  background-color: transparent;
}

