/* social send styles */
.jelscContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.webcamPreview {
  height: 100%;
  width: 100vw;
  object-fit: cover;
}

.feed {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.feedItem {
  margin-bottom: 0.5rem;
  max-width: 100%;
  max-height: calc(90vh - 220px);
}

.jelscDialogHeader {
  text-align: center;
  margin: 10px;
  white-space: pre-line;
  font-size: large;
}

.jelscMediaPreview {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.jelscDialogText {
  font-size: 12px;
  margin: 0px 5px;
  color: gray;
  text-align: center;
  margin-bottom: -5px;
  white-space: pre-line;
}

.jelscDialogActions {
  display: flex;
  padding: 24px;
}

.jelscButtonContainer {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 999;
  bottom: 35px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 6px;
  border-radius: 300px;
}
.mediaActionButtons,
.mediaActionButtons:hover,
.mediaActionButtons:active,
.mediaActionButtons:visited,
.mediaActionButtons:focus {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 100%;
  padding: 8px;
}
.captureCircle {
  border-radius: 100%;
  border: 2px solid rgba(255, 255, 255, 0.9);
  padding: 1px;
  background-color: transparent;
}
.captureButton {
  width: 26px;
  height: 22px;
  margin: 5px;
  object-fit: contain;
}

.cameraButton {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 999;
}

.capturedPhoto {
  width: 100%;
  object-fit: contain;
}

/* social submission ad space styles */
.socialSubmissionAd {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  border-width: 1px;
  border-style: solid;
}

.jelscMuteIcon {
  position: relative;
  margin-top: -60px;
  width: 30px;
  height: 30px;
  left: calc(100% - 50px);
}

.jelscActionBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -5px;
  width: 100%;
  height: 40px;
  background-color: #279fcc;
  text-transform: uppercase;
}
.time-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.timerCircleContainer {
  position: absolute;
  top: -16px;
  transform: translateY(-100%);
}
.timerCircleContainer svg path:nth-child(1) {
  stroke: rgba(255, 0, 0, 0.2);
}
