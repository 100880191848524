.root-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.settings-icon {
  position: fixed;
  top: 8px;
  right: 8px;
  color: white;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 100;
}
.settings-icon:hover {
  opacity: 1;
}
.dialog-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 200;
}

.masonry-grid {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.masonry-grid_column {
  background-clip: padding-box;
}

.loader-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.1);
}

.tile {
  position: relative;
  overflow: hidden;
  margin: 2px;
  text-align: center;
  color: white;
  padding: 20px;
  perspective: 1000px;
}

.tile img,
.tile video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.tile-face {
  backface-visibility: hidden; /* This hides the back side when it is rotated away from the viewer */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 1.2s; /* This animates the transition between the front and back face */
  transform-style: preserve-3d;
}

/* The back face should start flipped */
.back {
  transform: rotateY(180deg);
}

/* When the tile has the flipped class, toggle the rotation for both faces */
.tile.flipped .front {
  transform: rotateY(-180deg);
}

.tile.flipped .back {
  transform: rotateY(0deg);
}

.uv-fixed-masonry-grid {
  display: grid;
  grid-auto-flow: dense;
}
/* CSS FOR LAYOUT 4:3 START */
.card-4-0 {
  grid-column: span 2;
}
.card-4-1 {
  grid-row: span 2;
}

/* CSS FOR LAYOUT 4:3 END */
/* CSS FOR LAYOUT 16:9 START */
.card-7-0,
.card-7-3 {
  grid-row: span 2;
}
.card-7-2 {
  grid-column: span 2;
}

/* CSS FOR LAYOUT 16:9 END */
/* CSS FOR LAYOUT 16:9 Extended START */
.card-10-0,
.card-10-3 {
  grid-column: span 2;
  grid-row: span 1;
}
.card-10-1,
.card-10-4 {
  grid-row: span 2;
}
/* CSS FOR LAYOUT 16:9 Extended END */
.card-4-1 .flip-card-inner,
.card-4-2 .flip-card-inner,
.card-4-3 .flip-card-inner,
.card-7-0 .flip-card-inner,
.card-7-1 .flip-card-inner,
.card-7-3 .flip-card-inner,
.card-7-4 .flip-card-inner,
.card-7-5 .flip-card-inner,
.card-7-6 .flip-card-inner,
.card-10-1 .flip-card-inner,
.card-10-2 .flip-card-inner,
.card-10-4 .flip-card-inner,
.card-10-5 .flip-card-inner,
.card-10-6 .flip-card-inner,
.card-10-7 .flip-card-inner,
.card-10-8 .flip-card-inner,
.card-10-9 .flip-card-inner,
.card-grid-1 .flip-card-inner {
  aspect-ratio: 9 / 16;
}

.card-4-0 .flip-card-inner,
.card-7-2 .flip-card-inner,
.card-10-0 .flip-card-inner,
.card-10-3 .flip-card-inner,
.card-grid-2 .flip-card-inner {
  aspect-ratio: 16 / 9;
}

.card-4-0 .uv-main-media-tag,
.card-7-2 .uv-main-media-tag,
.card-10-0 .uv-main-media-tag,
.card-10-3 .uv-main-media-tag,
.card-grid-2 .uv-main-media-tag  {
  height: 100%;
  width: auto;
}
.card-4-1 .uv-main-media-tag,
.card-4-2 .uv-main-media-tag,
.card-4-3 .uv-main-media-tag,
.card-7-0 .uv-main-media-tag,
.card-7-1 .uv-main-media-tag,
.card-7-3 .uv-main-media-tag,
.card-7-4 .uv-main-media-tag,
.card-7-5 .uv-main-media-tag,
.card-7-6 .uv-main-media-tag,
.card-10-1 .uv-main-media-tag,
.card-10-2 .uv-main-media-tag,
.card-10-4 .uv-main-media-tag,
.card-10-5 .uv-main-media-tag,
.card-10-6 .uv-main-media-tag,
.card-10-7 .uv-main-media-tag,
.card-10-8 .uv-main-media-tag,
.card-10-9 .uv-main-media-tag,
.card-grid-1 .uv-main-media-tag {
  width: 100%;
  height: auto;
}

.mediacard-placeholder {
  width: 100%;
  height: 100%;

}
