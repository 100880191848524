/* Reset and base styles */
:root {
  --color-blue-500: #24b8e7;
  --color-blue-600: #2563eb;
  --color-blue-700: #1d4ed8;
  --color-gray-200: #e5e7eb;
  --color-gray-300: #d1d5db;
  --color-gray-400: #9ca3af;
  --color-gray-600: #4b5563;
  --color-gray-700: #374151;
  --color-gray-800: #222934;
  --color-gray-900: #0d0e13;
  --color-red-400: #f87171;
  --color-white: #ffffff;
}

.panel {
  width: 100%;
  height: 100%;
  background-color: var(--color-gray-800);
  border-radius: 0.75rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  border: 2px solid var(--color-gray-800);
}

/* Tabs */
.tabs {
  display: flex;
}

.tabs-action-buttons {
  margin-left: auto;
}

.tab-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-gray-400);
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
}

.tab-button:hover {
  color: var(--color-gray-200);
  background-color: rgba(55, 65, 81, 0.5);
}

.tab-button.active {
  color: var(--color-white);
  background-color: var(--color-gray-900);
  border-top: 2px solid var(--color-blue-500);
}

/* Content area */
.tp-content {
  padding: 2rem;
  display: flex;
  height: calc(100% - 4rem);
  background-color: var(--color-gray-900);
}

.tab-content {
  display: none;
  color: var(--color-gray-300);
  flex: 1;
}

.tab-content.active {
  display: block;
}

.content-section {
  margin-bottom: 1.5rem;
}

/* Search and filters */
.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

/* Buttons */
.button-group {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: var(--color-gray-200);
  background-color: var(--color-gray-700);
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s;
}

.button:hover {
  background-color: var(--color-gray-600);
}

.button-primary {
  color: var(--color-white);
  background-color: var(--color-blue-600);
}

.button-primary:hover {
  background-color: var(--color-blue-700);
}

.button-icon {
  padding: 0.5rem;
  color: var(--color-gray-400);
}

.button-icon:hover {
  color: var(--color-gray-200);
}

.button-danger:hover {
  color: var(--color-red-400);
}
.purge-button:hover {
  color: #c94444;
  background-color: rgba(201, 68, 68, 0.1);
}

/* Filter dropdown */
