.qcsDivOptionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  margin-bottom: 16;
}

.qcsMobileContainer {
  position: relative;
  max-width: 32%;
  margin-left: 16;
  overflow: hidden;
}

.qcsContentContainer {
  padding: 16;
  border-radius: 8;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-width: 100%;
  max-height: 86%;
  overflow: scroll;
  box-sizing: "border-box";
  font-size: "1em !important";
}

@media (max-width: 1079px) {
  /* .qcsDivOptionsContainer {
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
        .qcsMobileContainer {
    width: 42%;
    margin-bottom: 16;
  }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 .qcsContentContainer {
    font-size: "0.8em !important";
  */
}

.qcsContainer {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 86vh;
  flex-wrap: wrap;
  padding: 16px;
  border-radius: 8px;
}

.qcsBoxContainers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  padding: 16px;
  margin: 0 10px;
  margin-bottom: 16px;
}

.qcsOptionsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  margin-left: 40px;
  margin-right: 40px;
}

.qcsOptionBox {
  padding: 0px;
  border-radius: 4px;
  text-align: -webkit-center;
}

.qcsOptionText {
  font-size: 12px;
  font-weight: 700;
}

.qcsNoOptionsText {
  font-size: 16px;
}

.qcsButton {
  flex: 1;
  padding: 8px 12px;
  color: white;
}

.qcsImageContainer {
  width: 70%;
  padding-bottom: 70%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
}

.qcsGameImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: auto;
}

.qcsPhoneImage {
  width: 100%;
  display: block;
}
.qcsPagination {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qcsPaginationButton {
  margin: 8px;
  border-radius: 100px;
}

.qcsQuestionBox {
  padding: 8px;
  border-radius: 4px;
  margin: 8px;
  width: 80%;
}

.qcsQuestion {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  text-align: center;
}

.qcsDynamicParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3%;
}

.qcsOptionsBox {
  width: 100%;
  padding: 8px;
}

.qcsPageNumber {
  font-size: 8px;
}

.qcsLoaderStyle {
  position: absolute;
  align-self: center;
  z-index: 20;
}
.qcs-list-item {
  padding: 8px;
  margin: 8px;
  margin-bottom: 10px;
  background: var(--grey-1);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.qcs-list-item.selected {
  border: 1px solid var(--btn-blue);
}
.games-home {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.leftSideContainer {
  flex: 2;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.games-home .header {
  grid-area: header;
  align-items: center;
  background: var(--grey);
  display: flex;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
}
.games-home .header > .actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 8px;
  width: 100%;
}
.games-home .header .total {
  display: flex;
  margin-right: 20px;
  font-size: medium;
  color: white;
}

.games-home .monitor {
  height: calc(45% - 10px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
}

.games-home .monitor .iframeWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 100%;
  aspect-ratio: 16 / 9;
  background-color: var(--grey);
}
.monitor .iframeWrapper > iframe {
  border: none;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  background-color: var(--grey);
}
.games-home .list {
  height: 42%;
  background: var(--grey);
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 8px;
  overflow: auto;
  margin-bottom: 8px;
}

.games-home .list-item {
  padding: 8px;
  margin: 8px;
  background: var(--grey-1);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  padding-right: 100px;
  overflow: hidden;
}
.games-home .list-item.selected {
  border: 1px solid var(--btn-blue);
}
.games-home .add-light-show-button {
  /* background-color: var(--btn-blue); */
  color: white;
}

.games-preview > h3 {
  margin: 16px 0px;
  color: white;
}
.games-preview {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.monitorOverlay {
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
