.progressBarContainer {
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 5em;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}
.optionContent {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding: 0.75em 1.2em;
  font-weight: bold;
  width: 100%;
}
.percentageContainer {
  display: flex;
  align-items: center;
  margin-left: 16px;
  border-radius: 5em;
}
.percentage {
  margin-left: 8px;
  font-weight: 700;
}
.progressBarWrapper {
  display: flex;
  position: relative;
  width: calc(100% - 30px);
  height: 100%;
}

.avatar {
  height: 175%;
  aspect-ratio: 1;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 50%;
  z-index: 1;
  object-fit: cover;
  z-index: 2;
}
/* .left .avatar {
  right: 95%;
}
.right .avatar {
  left: 95%;
} */

/* .left .progressBarContainer {
  border-radius: 0px 5em 5em 0px;
  padding-left: 5%;
}
.right .progressBarContainer {
  border-radius: 5em 0px 0px 5em;
  padding-right: 5%;
} */
