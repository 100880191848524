.container {
  height: 100vh;
  width: 100vw;
  background-color: black;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
}

.card-carousel {
  display: inline-block;
  height: 80vh;
  width: 100%;
  margin: 10px;

  position: relative;
  background-color: transparent;
  transition: transform 0.5s;
}

.slick-center .card-carousel {
  transform: scale(1.5) !important; /* Adjust this scale value  */
  z-index: 5 !important; /* Ensure the zoomed item is above the others */
}

.slick-slide:not(.slick-center) .card-carousel {
  transform: scale(0.8) !important;
  z-index: 1 !important;
}

.slick-slide {
  z-index: 1 !important; /* This ensures the default stacking order */
}

.img-carousel,
.video-carousel {
  width: auto !important;
  display: block;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  max-height: calc(100% - 100px);
}

.slick-slider {
  position: absolute; /* Position the slider relative to the container */
  top: 55%; /* Position at 50% from the top of the container */
  left: 50%; /* Position at 50% from the left of the container */
  transform: translate(-50%, -50%); /* Center the slider */
  width: 100%; /* Take the full width */
  height: 100vh; /* Adjust as needed */
}

.slick-list {
  height: 100%;
}

.slick-track {
  height: 100%;
  display: flex;
  align-items: center;
}

.carousel .slide {
  /* transition: transform 0.5s ease; */
  opacity: 0.5;
}

.carousel .slide.selected {
  transform: scale(1.2);
  opacity: 1; /* Make the centered slide fully opaque */
  z-index: 5;
}

.carousel .carousel-slider {
  overflow: visible; /* Ensure that the partially visible slides are not clipped */
}

.carousel-root {
  margin-top: 3%;
}
.socialCarouselSocialMediaPortal {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.socialCarouselSettingsIcon {
  position: fixed;
  top: 8px;
  right: 8px;
  color: white;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 100;
}

.socialCarouselSettingsIcon:hover {
  opacity: 1;
}

.socialCarouselDialogContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 200;
}
