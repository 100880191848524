/* index styles */
.metricsContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.metricsContentContainer {
  margin-top: 8px;
  background-color: #222934;
  padding: 16px;
  border-radius: 8px;
  flex: 1;
  display: flex;
}

/* user emails styles */
.userEmailsRoot {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
}

.userEmailsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px;
}

.userEmailsOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 1000;
}

.userEmailsOptionsDropdown {
  right: 0;
  top: 0;
  z-index: 100;
  width: 150px;
  height: 50px;
  background-color: white;
}

.userEmailsContainer {
  margin: auto;
  justify-content: start;
  width: 100%;
  overflow: auto;
  flex: 1;
  max-height: calc(100% - 160px);
}

.userEmailsCard {
  max-width: 30vw;
  max-height: 100px;
  margin: 0px 8px 8px 0px;
  background-color: transparent;
  border-width: 1px;
  border-color: grey;
  border-style: solid;
  text-align: justify;
}

.userEmailsEmailAddress {
  margin: 5px;
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.userEmailsCaptureSource {
  margin: 5px;
  color: white;
  font-size: 12px;
}

.userEmailsBottomButtons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
