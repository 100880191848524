.cardContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.card {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 60vw;
  aspect-ratio: 16/9;
  border-radius: 25px;
  padding: 32px;
  transform-origin: top;
  border-radius: 10px 150px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-blend-mode: soft-light;
}
.card h2 {
  text-align: center;
  margin: 0px;
  text-transform: capitalize;
}
.card h2::first-letter {
  text-transform: uppercase;
}
.card .cardBody {
  display: flex !important;
  height: 100%;
  gap: 50px;
}
.description {
  position: relative;
  font-family: Montserrat;
  font-style: oblique;
  display: flex;
  justify-content: center;
  max-height: 80%;
  max-width: 100%;
  p {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: lowercase;
  }
  p::first-letter {
    text-transform: uppercase;
  }
}

.imageContainer {
  position: relative;
  width: auto;
  height: 120%;
  aspect-ratio: 9 / 16;
  border-radius: 25px;
  overflow: hidden;
  top: -52px;
  background-color: black;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

  z-index: 1;
  .inner {
    width: 100%;
    height: 100%;
    display: flex;
  }
  img:not(.blur-bg-media),
  video:not(.blur-bg-media) {
    object-fit: contain;
  }
}
.textContainer {
  flex: 1;
}

.uv-main-media-tag {
  width: 100%;
  height: auto;
}
.quote-icon-container {
  position: absolute;
  width: 110px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  border-color: white;
}
.top-side {
  top: -1px;
  left: 20px;
  transform: rotate(180deg);
}
.bottom-side {
  bottom: -1px;
  right: 20px;
}
.quote-icon-container:after {
  content: " ";
  position: absolute;
  left: 0;
  top: -1px;
  width: 0;
  height: 0;
  border-top: 13px solid currentColor;
  border-left: 55px solid transparent;
  border-right: 55px solid transparent;
}
.blur-bg-media {
  object-fit: fill;
  filter: blur(10px);
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  z-index: -1;
  overflow: hidden;
}
