:root {
  --crawl-base-speed: 180s;
  --slow-base-speed: 150s;
  --medium-base-speed: 120s;
  --fast-base-speed: 90s;
  --uv-animation: linear 0s infinite slide-up;
}

/* .my-masonry-grid {
  display: flex;
  width: auto;
  margin-left: -30px;
}

.my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  margin-bottom: 30px;
} */
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.item {
  box-sizing: border-box;
}

.thumbnail img {
  width: 100%;
  height: auto;
  display: block;
}
/* @keyframes slide {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
.socailPageSlide {
  animation: 10s slide infinite linear;
  height: 100vh;
  overflow-y: auto;
} */
.scrolling-card {
  aspect-ratio: 9 / 16;
}
.scrolling-card * {
  width: 102%;
}

.root-container-scrolling {
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 100vh;
}

@keyframes slide-up {
  0% {
    transform: translateY(0%);
    /* animation-delay: 1s; */
  }
  50%,
  50% {
    transform: translateY(-100%);
    /* animation-delay: 1s; */
  }
  100%,
  100% {
    transform: translateY(-200%);
    /* animation-delay: 1s; */
  }
}

.crawl-1 .scrolling-slide {
  animation: var(--crawl-base-speed) var(--uv-animation);
}
.crawl-2 .scrolling-slide {
  animation: calc(var(--crawl-base-speed) / 2) var(--uv-animation);
}
.crawl-3 .scrolling-slide {
  animation: calc(var(--crawl-base-speed) / 3) var(--uv-animation);
}
.crawl-4 .scrolling-slide {
  animation: calc(var(--crawl-base-speed) / 4) var(--uv-animation);
}
.crawl-5 .scrolling-slide {
  animation: calc(var(--crawl-base-speed) / 5) var(--uv-animation);
}
.crawl-6 .scrolling-slide {
  animation: calc(var(--crawl-base-speed) / 6) var(--uv-animation);
}
.crawl-7 .scrolling-slide {
  animation: calc(var(--crawl-base-speed) / 7) var(--uv-animation);
}
.crawl-8 .scrolling-slide {
  animation: calc(var(--crawl-base-speed) / 8) var(--uv-animation);
}

.slow-1 .scrolling-slide {
  animation: var(--slow-base-speed) var(--uv-animation);
}
.slow-2 .scrolling-slide {
  animation: calc(var(--slow-base-speed) / 2) var(--uv-animation);
}
.slow-3 .scrolling-slide {
  animation: calc(var(--slow-base-speed) / 3) var(--uv-animation);
}
.slow-4 .scrolling-slide {
  animation: calc(var(--slow-base-speed) / 4) var(--uv-animation);
}
.slow-5 .scrolling-slide {
  animation: calc(var(--slow-base-speed) / 5) var(--uv-animation);
}
.slow-6 .scrolling-slide {
  animation: calc(var(--slow-base-speed) / 6) var(--uv-animation);
}
.slow-7 .scrolling-slide {
  animation: calc(var(--slow-base-speed) / 7) var(--uv-animation);
}
.slow-8 .scrolling-slide {
  animation: calc(var(--slow-base-speed) / 8) var(--uv-animation);
}

.medium-1 .scrolling-slide {
  animation: var(--medium-base-speed) var(--uv-animation);
}
.medium-2 .scrolling-slide {
  animation: calc(var(--medium-base-speed) / 2) var(--uv-animation);
}
.medium-3 .scrolling-slide {
  animation: calc(var(--medium-base-speed) / 3) var(--uv-animation);
}
.medium-4 .scrolling-slide {
  animation: calc(var(--medium-base-speed) / 4) var(--uv-animation);
}
.medium-5 .scrolling-slide {
  animation: calc(var(--medium-base-speed) / 5) var(--uv-animation);
}
.medium-6 .scrolling-slide {
  animation: calc(var(--medium-base-speed) / 6) var(--uv-animation);
}
.medium-7 .scrolling-slide {
  animation: calc(var(--medium-base-speed) / 7) var(--uv-animation);
}
.medium-8 .scrolling-slide {
  animation: calc(var(--medium-base-speed) / 8) var(--uv-animation);
}

.fast-1 .scrolling-slide {
  animation: var(--fast-base-speed) var(--uv-animation);
}
.fast-2 .scrolling-slide {
  animation: calc(var(--fast-base-speed) / 2) var(--uv-animation);
}
.fast-3 .scrolling-slide {
  animation: calc(var(--fast-base-speed) / 3) var(--uv-animation);
}
.fast-4 .scrolling-slide {
  animation: calc(var(--fast-base-speed) / 4) var(--uv-animation);
}
.fast-5 .scrolling-slide {
  animation: calc(var(--fast-base-speed) / 5) var(--uv-animation);
}
.fast-6 .scrolling-slide {
  animation: calc(var(--fast-base-speed) / 6) var(--uv-animation);
}
.fast-7 .scrolling-slide {
  animation: calc(var(--fast-base-speed) / 7) var(--uv-animation);
}
.fast-8 .scrolling-slide {
  animation: calc(var(--fast-base-speed) / 8) var(--uv-animation);
}

/* .scrolling-slide {
  animation: 15s var(--uv-animation);
} */

.video-media-card img {
  display: none;
}
.img-media-card video {
  display: none;
}
