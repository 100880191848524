.sidebar {
  overflow: auto;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 10px;
  cursor: pointer;
}

.eventImage {
  margin-bottom: 20px;
  /* Add additional styles for the event image/profile image */
}

.button {
  padding: 4px;
  margin-bottom: 2px;
  margin-right: 8px;
  /* Add additional styles for the buttons */
}

button:hover {
  background-color: #e6e6e6;
}

.profilePicture {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.EventTitle {
  text-align: center;
  padding: 10px 30px;
  font-size: 18px;
  color: white;
  -webkit-font-smoothing: subpixel-antialiased;
  font-weight: 800;
  position: relative;
  width: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-container {
  display: flex;
  /* background-image: linear-gradient(
    to right,
    rgba(57, 58, 137, 1),
    rgba(82, 170, 160, 1)
  ); */
  /* background-color: "#0D1051"; */
  position: absolute;
  inset: 0;
}

.sidebar {
  width: 250px;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  /* background-image: linear-gradient(
    to right,
    rgba(57, 58, 137, 1),
    rgba(82, 170, 160, 1)
  ); */
}

/* cloned from react styling */
.sideTabButton {
  margin: 5px;
  border-width: 1px;
  border-style: solid;
  width: 100%;
  height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  border-radius: 3px;
  justify-content: space-between;
}

.sideTabTitle {
  display: flex;
}

.toggleButton {
  margin: 5px;
  background-color: transparent;
  width: 100%;
  height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  border-radius: 3px;
  justify-content: space-between;
}

.toggleButton:hover {
  background-color: transparent;
}

.toggleSwitch {
  zoom: 0.7;
}

.onlineUsersStrip {
  position: relative;
  margin-top: 20px;
  width: 100%;
  text-align: center;
}

.usersDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  text-align: center;
}

.connectedUsers {
  display: flex;
  align-items: center;
  justify-content: center;
}

.userConnectionText {
  font-size: 18px;
  font-weight: 200px;
  padding: 5px 10px;
  text-align: center;
}

.userConnectionCount {
  font-size: 20px;
  font-weight: 600;
  padding: 5px 10px;
}
