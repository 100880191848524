.light-show-home {
  display: flex;
  border-radius: 8px;
  color: white;
  flex: 1;
}
.light-show-home .console {
  flex: 2;
  display: flex;
  flex-direction: column;
}
.light-show-home .preview {
  flex: 1;
}
.show-list-container,
.audio-upload {
  display: flex;
  flex-direction: column;
  height: calc(50% - 8px);
}
.audio-upload {
  height: calc(50% - 8px);
  margin-top: 16px;
}
.light-show-home .header {
  grid-area: header;
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  position: sticky;
  top: 0;
  z-index: 1;
}
.light-show-home .header > .actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 8px;
  width: 100%;
}
.light-show-home .header > .total {
  display: flex;
  margin-right: 20px;
  font-size: medium;
}
/* .light-show-home .header button:not(.MuiToggleButton-root) {
  margin-right: 20px;
} */
.light-show-home .preview {
  grid-area: preview;
}
.grey-card {
  background: var(--grey);
  padding: 8px;
  border-radius: 8px;
}

.light-show-home .list {
  height: 100%;
  overflow-y: auto;
}

.light-show-home .list-item {
  padding: 8px;
  margin: 8px;
  background: var(--grey-1);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  padding-right: 100px;
  overflow: hidden;
}
.light-show-home .list-item.selected {
  border: 1px solid var(--btn-blue);
}
.add-light-show-button {
  /* background-color: var(--btn-blue); */
  color: white;
}

.light-show-home .preview > h4 {
  margin: 16px 0px;
}
.light-show-home .preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.green-bg {
  margin: -8px;
  padding: 8px;
  border-radius: 4px 4px 0px 0px;
}
.light-show-details * {
  color: white;
}

.lightShowTitleContainer {
  width: 100%;
  display: inline-flex;
  align-items: center;
  transform: translateX(-93px);
}
.lightShowTitleContainer:hover {
  transform: translateX(0px);
  transition: transform 0.5s;
}
.actionButtonsDiv {
  border-right: 2px solid;
  padding-right: 8px;
}
.socket-connection-toggle {
  background-color: #323a4a;
  color: rgba(128, 0, 0, 0.7);
}
.socket-connection-toggle:hover {
  background-color: rgba(0, 0, 0, 0.54) rgba(128, 0, 0, 1);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  border: 1px solid #323a4a;
}
@keyframes blink {
  50% {
    opacity: 0;
  }
}
.blink {
  animation: blink 1s step-start 0s infinite;
}
.torchImage {
  width: 68px;
  margin-top: 20px;
}
.audio-file-item {
  padding: 8px;
  margin: 8px;
  background: var(--grey-1);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.countdown-container {
  position: absolute;
  z-index: 1;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  padding: 0.25rem 1rem;
}
.countdown-container .countdown {
  font-size: 2rem;
  text-align: center;
}
.countdown-container .countdown-label {
  font-size: 1.75rem;
  text-align: center;
}
