/* ad box preview styles */
.adBoxDialogPaper {
    max-width: 70%;
    max-height: 70vh;
    flex: 1;
    display: flex;
    border-radius: 15px;
}

.adBoxDialogHeader {
    text-align: center;
    margin: 5px;
}

.adBoxDialogClose {
    position: absolute;
    top: 0;
    right: 20px;
    margin: 5px;
    cursor: pointer;
    text-align: center;
}

.adBoxMediaPreview {
    display: flex;
    overflow: hidden;
}

.adBoxVideoPreviewContainer {
    width: 100%;
    height: 40vh;
}

.adBoxDialogActions {
    justify-content: center;
}

/* index styles */
.buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 8px;
}

.tabButton {
    margin: 8px;
    border-width: 1px;
    border-style: solid;
}

.creationContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    padding: 16px;
    border-radius: 8px;
    overflow: auto
}

.listingContainer {
    display: flex;
    flex-direction: "row";
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    padding: 16px;
    border-radius: 8px;
    overflow: auto
}

.saveButton {
    margin: 8px
}

/* in event ad styles */
.adRoot {
    margin: 10px;
    width: 300px;
}

.adMedia {
    width: 200px;
    height: 80px;
    border-style: solid;
    border-radius: 10px
}

/* post game ad styles */
/* social ad styles */
.emailCheckbox {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
}